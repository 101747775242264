import { httpErrorHandler } from "@/api/api";
import {
  useEditLocationMutation,
  useGetLocationQuery,
} from "@/api/queries/locationsQueries";
import { Button } from "@/components/ui/button/Button";
import {
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog/Dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form/Form";
import { Input } from "@/components/ui/input/Input";
import { Textarea } from "@/components/ui/input/textarea/Textarea";
import { Spinner } from "@/components/ui/spinner/Spinner";
import { useToast } from "@/components/ui/toast/useToast";
import {
  EditLocationSchema,
  EditLocationSchemaType,
} from "@/schemas/location.schema";
import { LocationI } from "@/types/locations";
import { serializeData } from "@/utils/serializeData";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

type LocationEditFormPropsT = {
  onClose: () => void;
  location: LocationI | undefined;
};

export default function EditLocation({
  location,
  onClose,
}: LocationEditFormPropsT) {
  const { toast } = useToast();

  const { mutateAsync, isPending: isPendingMutation } =
    useEditLocationMutation();
  const {
    data,
    error,
    isError,
    isPending: isPendingQuery,
  } = useGetLocationQuery({ id: location?.id });
  const handleClose = () => {
    onClose();
    form.reset();
  };

  const form = useForm<EditLocationSchemaType>({
    defaultValues: {
      name: "",
      desc: "",
      coordinates: "",
    },
    values: data && EditLocationSchema.cast(serializeData(data)),
    mode: "onBlur",
    resolver: yupResolver(EditLocationSchema),
  });

  useEffect(() => {
    if (isError) {
      const { title, detail } = httpErrorHandler(error);
      toast({
        variant: "destructive",
        title: title,
        description: detail,
      });
      onClose();
    }
  }, [error, isError, onClose, toast]);

  const onSubmit = (data: EditLocationSchemaType) => {
    mutateAsync(data)
      .then(() => {
        handleClose();
      })
      .catch((error) => {
        const { detail } = httpErrorHandler(error);
        toast({
          variant: "destructive",
          description: detail,
        });
      });
  };

  return (
    <DialogContent
      onCloseAutoFocus={handleClose}
      onEscapeKeyDown={handleClose}
      className={"max-w-[45ch]"}
    >
      {isPendingMutation && (
        <div
          className={
            "absolute inset-0 z-20 m-auto flex flex-wrap items-center justify-center gap-3 bg-bg-container"
          }
        >
          <Spinner size={"lg"} />
          <h5 className={"font-medium"}>Trwa edycja lokacji...</h5>
        </div>
      )}
      {isPendingQuery && (
        <div
          className={
            "absolute inset-0 z-20 m-auto flex flex-wrap items-center justify-center gap-3 bg-bg-container"
          }
        >
          <Spinner size={"lg"} />
          <h5 className={"font-medium"}>Trwa ładowanie danych...</h5>
        </div>
      )}

      <DialogHeader>
        <DialogTitle>Edytuj lokację</DialogTitle>
      </DialogHeader>
      <Form {...form}>
        <DialogBody className={"flex gap-4 sm:flex-col"}>
          <form
            className={"flex w-full flex-col gap-3"}
            onSubmit={form.handleSubmit(onSubmit)}
            noValidate
          >
            <FormField
              name={"name"}
              control={form.control}
              render={({ field }) => (
                <FormItem className={"col-span-1"}>
                  <FormLabel>Nazwa</FormLabel>
                  <FormControl>
                    <Input {...field} placeholder={"Nazwa lokacji"} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name={"coordinates"}
              control={form.control}
              render={({ field }) => (
                <FormItem className={"col-span-1"}>
                  <FormLabel>Lokalizacja</FormLabel>
                  <FormControl>
                    <Input {...field} placeholder={"Podaj lokajizację"} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name={"desc"}
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    Opis{" "}
                    <small className={"text-sm text-fg-muted"}>
                      (opcionalne)
                    </small>
                  </FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder={"Opis"}
                      className={"min-h-[5rem]"}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </form>
        </DialogBody>
        <DialogFooter>
          <Button
            onClick={handleClose}
            type={"button"}
            variant={"flat"}
            variantColor={"muted"}
            className={"sm:w-full"}
          >
            Anuluj
          </Button>
          <Button
            onClick={form.handleSubmit(onSubmit)}
            type={"submit"}
            variant={"flat"}
            variantColor={"brand"}
            className={"sm:w-full"}
          >
            Zapisz
          </Button>
        </DialogFooter>
      </Form>
    </DialogContent>
  );
}
