import { useInfiniteQueryResult } from "@/api/api-utils";
import {
  useEditDirectoryMutation,
  useGetDirectoryPermissionsQuery,
} from "@/api/queries/directoryQueries";
import {
  useEditFileMutation,
  useGetFilePermissionsQuery,
} from "@/api/queries/filesQueries";
import { useGetGroupsInfiniteQuery } from "@/api/queries/groupsQueries";
import { useGetUsersInfiniteQuery } from "@/api/queries/usersQueries";
import PermissionLiEntity from "@/components/features/files/PermissionLiEntity";
import { Badge } from "@/components/ui/badge/Badge";
import { Button } from "@/components/ui/button/Button";
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog/Dialog";
import { Input, InputActionButton } from "@/components/ui/input/Input";
import { LiElement } from "@/components/ui/list/ListElement";
import SelectDataList from "@/components/ui/list/SelectDataList";
import { Tabs, TabsContent } from "@/components/ui/tabs/Tabs";
import { useToast } from "@/components/ui/toast/useToast";
import { useDebounceValue } from "@/hooks/useDebounceValue";
import {
  DirectoryDirI,
  DirectoryFileI,
  GetPermissionsGroupI,
  GetPermissionsUserI,
} from "@/types/files";
import { UsersI } from "@/types/users";
import { getIsFSEntityFile } from "@/utils/files";
import { ChevronLeft, Plus, Search, X } from "lucide-react";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";

type StorageElementShareFormProps = {
  element?: DirectoryDirI | DirectoryFileI;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  submitCallback?: () => void;
  directoryId: string;
};

type permissionChangesProps = {
  selectedUsers?: GetPermissionsUserI[];
  selectedGroups?: GetPermissionsGroupI[];
  defaultUsers?: GetPermissionsUserI[];
  defaultGroups?: GetPermissionsGroupI[];
};

function permissionChanges({
  selectedUsers,
  selectedGroups,
  defaultUsers,
  defaultGroups,
}: permissionChangesProps) {
  const payload = [];
  const readPermissionsUsers: number[] = [];
  const writePermissionsUsers: number[] = [];
  const editPermissionsUsers: number[] = [];
  const readPermissionsGroups: number[] = [];
  const writePermissionsGroups: number[] = [];
  const editPermissionsGroups: number[] = [];

  const usersToRemove = (defaultUsers || [])
    .filter((user) => !selectedUsers?.some((element) => element.id === user.id))
    .map((user) => user.id);

  const groupsToRemove = (defaultGroups || [])
    .filter(
      (group) => !selectedGroups?.some((element) => element.id === group.id),
    )
    .map((group) => group.id);

  const usersToAdd = selectedUsers?.filter(
    (user) =>
      !defaultUsers?.some(
        (u) => u.id === user.id && u.permissions === user.permissions,
      ),
  );

  const groupsToAdd = selectedGroups?.filter(
    (group) =>
      !defaultGroups?.some(
        (g) => g.id === group.id && g.permissions === group.permissions,
      ),
  );

  groupsToAdd?.forEach((group) => {
    if (group.permissions.edit) {
      editPermissionsGroups.push(group.id);
      return;
    }
    if (group.permissions.write) {
      writePermissionsGroups.push(group.id);
      return;
    }
    if (group.permissions.read) {
      readPermissionsGroups.push(group.id);
      return;
    }
  });

  usersToAdd?.forEach((user) => {
    if (user.permissions.edit) {
      editPermissionsUsers.push(user.id);
      return;
    }
    if (user.permissions.write) {
      writePermissionsUsers.push(user.id);
      return;
    }
    if (user.permissions.read) {
      readPermissionsUsers.push(user.id);
      return;
    }
  });

  if (usersToRemove.length > 0 || groupsToRemove.length > 0) {
    payload.push({
      userIds: usersToRemove,
      groupIds: groupsToRemove,
      remove: true,
    });
  }

  if (readPermissionsUsers.length > 0 || readPermissionsGroups.length > 0) {
    payload.push({
      userIds: readPermissionsUsers,
      groupIds: readPermissionsGroups,
      add: true,
      read: true,
      write: false,
      edit: false,
    });
  }

  if (writePermissionsUsers.length > 0 || writePermissionsGroups.length > 0) {
    payload.push({
      userIds: writePermissionsUsers,
      groupIds: writePermissionsGroups,
      add: true,
      read: true,
      write: true,
      edit: false,
    });
  }

  if (editPermissionsUsers.length > 0 || editPermissionsGroups.length > 0) {
    payload.push({
      userIds: editPermissionsUsers,
      groupIds: editPermissionsGroups,
      add: true,
      read: true,
      write: true,
      edit: true,
    });
  }

  return payload;
}

export default function ShareFSEntry(props: StorageElementShareFormProps) {
  const { element, open, directoryId, onOpenChange, submitCallback } = props;

  const { toast } = useToast();
  const [selectedTab, setSelectedTab] = useState<string>("main_menu");

  //selected Entities
  const [selectedUsers, setSelectedUsers] = useState<GetPermissionsUserI[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<GetPermissionsGroupI[]>(
    [],
  );
  const isFile = getIsFSEntityFile(element);

  const {
    mutateAsync: directoriesMutation,
    isPending: isDirectoryMutationPending,
  } = useEditDirectoryMutation(directoryId);

  const { mutateAsync: filesMutation, isPending: isFileMutationPending } =
    useEditFileMutation();

  const filePermissionsQuery = useGetFilePermissionsQuery(
    element?.id,
    open && isFile,
  );

  const directoryPermissionsQuery = useGetDirectoryPermissionsQuery(
    element?.id,
    open && !isFile,
  );

  const handleClose = useCallback(() => {
    setSelectedUsers([]);
    setSelectedGroups([]);
    onOpenChange(false);
  }, [onOpenChange]);

  const handleSubmit = async () => {
    if (!element) {
      toast({
        title: "Błąd",
        description: "Formularz nie zawiera poprawnego pliku lub folderu",
        variant: "destructive",
      });
      return;
    }

    const permissions = permissionChanges({
      defaultUsers: isFile
        ? filePermissionsQuery.data?.users
        : directoryPermissionsQuery.data?.users,
      defaultGroups: isFile
        ? filePermissionsQuery.data?.groups
        : directoryPermissionsQuery.data?.groups,
      selectedUsers,
      selectedGroups,
    });

    if (isFile) {
      await filesMutation({
        id: element.id,
        permissionChanges: permissions,
      }).then(() => {
        handleClose();
        submitCallback?.();
      });
    } else {
      await directoriesMutation({
        id: element.id,
        permissionChanges: permissions,
      }).then(() => {
        handleClose();
        submitCallback?.();
      });
    }
  };

  useEffect(() => {
    if (isFile) {
      if (filePermissionsQuery.isError) {
        handleClose();
        toast({
          title: "Błąd",
          description: "Nie udało się pobrać uprawnień do pliku",
          variant: "destructive",
        });
      }

      setSelectedUsers(filePermissionsQuery.data?.users || []);
      setSelectedGroups(filePermissionsQuery.data?.groups || []);
    } else {
      if (directoryPermissionsQuery.isError) {
        handleClose();
        toast({
          title: "Błąd",
          description: "Nie udało się pobrać uprawnień do folderu",
          variant: "destructive",
        });
      }
      setSelectedUsers(directoryPermissionsQuery.data?.users || []);
      setSelectedGroups(directoryPermissionsQuery.data?.groups || []);
    }
  }, [
    filePermissionsQuery.data,
    directoryPermissionsQuery.data,
    isFile,
    filePermissionsQuery.isError,
    handleClose,
    toast,
    directoryPermissionsQuery.isError,
  ]);

  return (
    <Dialog onOpenChange={onOpenChange} open={open}>
      <Tabs
        asChild
        defaultValue={"main_menu"}
        onValueChange={setSelectedTab}
        value={selectedTab}
      >
        <DialogContent
          className={"h-[664px] w-[55ch] sm:w-full"}
          onInteractOutside={(e) => e.preventDefault()}
          onPointerDownOutside={(e) => e.preventDefault()}
        >
          <TabsContent
            value={"main_menu"}
            className={"flex flex-col data-[state=active]:h-full"}
          >
            <DialogHeader>
              <DialogTitle>Dostęp</DialogTitle>
            </DialogHeader>
            <DialogBody className={"flex h-auto flex-col gap-4"}>
              <div
                className={"flex flex-col gap-4 border-b-1 border-border pb-4"}
              >
                <div className={"flex items-center justify-between"}>
                  <h5 className={"text-sm font-medium text-fg-muted"}>
                    Element:
                  </h5>
                </div>
                <div className="flex flex-wrap gap-2">
                  <Badge variant={"outline"}>{element?.name}</Badge>
                </div>
              </div>
              <ShareFSEntryUsersList
                setSelected={setSelectedUsers}
                setTab={setSelectedTab}
                selected={selectedUsers}
              />
              <ShareFSEntryGroupsList
                setSelected={setSelectedGroups}
                setTab={setSelectedTab}
                selected={selectedGroups}
              />
            </DialogBody>
            <DialogFooter>
              <Button
                onClick={handleClose}
                variant={"flat"}
                variantColor={"muted"}
                className={"sm:w-full"}
              >
                Anuluj
              </Button>
              <Button
                isLoading={{
                  state: isFileMutationPending || isDirectoryMutationPending,
                }}
                onClick={handleSubmit}
                variant={"flat"}
                variantColor={"brand"}
                className={"sm:w-full"}
              >
                Zapisz
              </Button>
            </DialogFooter>
          </TabsContent>
          <TabsContent
            value={"users_add_menu"}
            className={"flex flex-col data-[state=active]:h-full"}
          >
            <ShareFSEntryUsersSelectedDataList
              setTab={setSelectedTab}
              selected={selectedUsers}
              open={open}
              setSelected={setSelectedUsers}
            />
          </TabsContent>
          <TabsContent
            value={"groups_add_menu"}
            className={"flex flex-col data-[state=active]:h-full"}
          >
            <ShareFSEntryGroupsSelectedDataList
              setTab={setSelectedTab}
              selected={selectedGroups}
              open={open}
              setSelected={setSelectedGroups}
            />
          </TabsContent>
        </DialogContent>
      </Tabs>
    </Dialog>
  );
}

type ShareFSEntryListProps<SelectedT> = {
  selected: SelectedT[];
  setTab: Dispatch<SetStateAction<string>>;
  setSelected: Dispatch<SetStateAction<SelectedT[]>>;
};

function ShareFSEntryUsersList(
  props: ShareFSEntryListProps<GetPermissionsUserI>,
) {
  const { selected, setTab, setSelected } = props;

  const handleRemoveEntity = useCallback(
    (id: number) => {
      setSelected((prev) => prev.filter((user) => user.id !== id));
    },
    [setSelected],
  );
  const handlePermissionChange = useCallback(
    (id: number, newValue: string) => {
      setSelected((prev) =>
        prev.map((user) =>
          user.id === id
            ? {
                ...user,
                permissions: {
                  read: false,
                  write: false,
                  edit: false,
                  [newValue]: true,
                },
              }
            : user,
        ),
      );
    },
    [setSelected],
  );

  return (
    <div className={"flex flex-col gap-4"}>
      <div className={"flex items-center justify-between"}>
        <h5 className={"text-lg font-medium"}>Użytkownicy:</h5>
        <Button
          onClick={() => setTab("users_add_menu")}
          size={"sm"}
          variant={"flat"}
          variantColor={"muted"}
          icon={<Plus />}
          iconPosition={"left"}
        >
          Użytkownicy
        </Button>
      </div>
      <ul>
        {selected.length === 0 && (
          <div className="flex py-4">
            <p className="text-sm italic text-fg-muted">Dodaj użytkowników</p>
          </div>
        )}
        {selected.map((entity) => (
          <PermissionLiEntity
            key={entity.id}
            data={{
              id: entity.id,
              title: entity.name + " " + entity.surname,
              avatarURL: entity.avatarURL,
            }}
            permissions={entity.permissions}
            sources={entity.sources}
            onPermissionChange={(id, value) =>
              handlePermissionChange(id, value)
            }
            onRemove={() => {
              handleRemoveEntity(entity.id);
            }}
          />
        ))}
      </ul>
    </div>
  );
}

function ShareFSEntryGroupsList(
  props: ShareFSEntryListProps<GetPermissionsGroupI>,
) {
  const { selected, setTab, setSelected } = props;

  const handleRemoveEntity = useCallback(
    (id: number) => {
      setSelected((prev) => prev.filter((group) => group.id !== id));
    },
    [setSelected],
  );
  const handlePermissionChange = useCallback(
    (id: number, newValue: string) => {
      setSelected((prev) =>
        prev.map((group) =>
          group.id === id
            ? {
                ...group,
                permissions: {
                  read: false,
                  write: false,
                  edit: false,
                  [newValue]: true,
                },
              }
            : group,
        ),
      );
    },
    [setSelected],
  );

  return (
    <div className={"flex flex-col gap-4"}>
      <div className={"flex items-center justify-between"}>
        <h5 className={"text-lg font-medium"}>Grupy:</h5>
        <Button
          onClick={() => setTab("groups_add_menu")}
          size={"sm"}
          variant={"flat"}
          variantColor={"muted"}
          icon={<Plus />}
          iconPosition={"left"}
        >
          Grupy
        </Button>
      </div>
      <ul>
        {selected.length === 0 && (
          <div className="flex py-4">
            <p className="text-sm italic text-fg-muted">Dodaj grupy</p>
          </div>
        )}
        {selected.map((entity) => (
          <PermissionLiEntity
            key={entity.id}
            permissions={entity.permissions}
            data={{
              id: entity.id,
              title: entity.name,
              avatarURL: "",
            }}
            sources={entity.sources}
            onRemove={() => {
              handleRemoveEntity(entity.id);
            }}
            onPermissionChange={(id, value) =>
              handlePermissionChange(id, value)
            }
          />
        ))}
      </ul>
    </div>
  );
}

type ShareFSEntrySelectedDataListProps<SelectedT> = {
  setTab: Dispatch<SetStateAction<string>>;
  selected: SelectedT[];
  open: boolean;
  setSelected: Dispatch<SetStateAction<SelectedT[]>>;
};

function ShareFSEntryUsersSelectedDataList(
  props: ShareFSEntrySelectedDataListProps<GetPermissionsUserI>,
) {
  const { setTab, selected, open, setSelected } = props;

  const [searchUserValue, setSearchUserValue] = useState<string>("");
  const searchUserDebouncedValue = useDebounceValue(searchUserValue, 400);

  const getUsersInfiniteQuery = useGetUsersInfiniteQuery({
    name: searchUserDebouncedValue,
    enabled: open,
  });

  const {
    data: users,
    isEmpty: usersIsEmpty,
    noResults: usersNoResults,
  } = useInfiniteQueryResult(
    getUsersInfiniteQuery.data,
    searchUserDebouncedValue,
  );

  const setSelectedCb = useCallback(
    (selected: any[]) => {
      setSelected(() => {
        if (selected) {
          return selected.map((user): GetPermissionsUserI => {
            if ("permissions" in user) {
              return user as GetPermissionsUserI;
            }
            return {
              ...user,
              permissions: {
                read: true,
                write: false,
                edit: false,
              },
            } as GetPermissionsUserI;
          });
        }
        return [];
      });
    },
    [setSelected],
  );

  const isUserInherited = useCallback(
    (user: UsersI) => {
      return selected.some(
        (u) =>
          Number(u.id) === Number(user.id) &&
          u.sources?.some((s) => s?.isInherited),
      );
    },
    [selected],
  );

  return (
    <>
      <DialogBody className="h-auto">
        <header
          className={"sticky top-0 z-20 flex gap-3 bg-bg-container py-4 pb-2"}
        >
          <Button
            variant={"outline"}
            variantColor={"muted"}
            icon={<ChevronLeft />}
            iconPosition={"only"}
            onClick={() => {
              setTab("main_menu");
            }}
          />
          <Input
            placeholder={"Wyszukaj..."}
            value={searchUserValue}
            onChange={(e) => setSearchUserValue(e.target.value)}
            startContent={<Search className={"ml-2"} />}
            endContent={
              <InputActionButton
                disabled={!searchUserValue.length}
                onClick={() => setSearchUserValue("")}
                icon={<X />}
              />
            }
          />
        </header>
        <SelectDataList
          query={getUsersInfiniteQuery}
          selected={selected}
          setSelected={setSelectedCb}
          isEmpty={usersIsEmpty}
          isNoResults={usersNoResults}
          emptyMessage={"Wyszukaj użytkownika"}
          searchNoResultsMessage={"Nie znaleziono użytkownika"}
          loadingElementPros={{
            hasAvatar: true,
            hasDetail: false,
            hasTitle: true,
          }}
        >
          {users.map((entity) => {
            const disabled = isUserInherited(entity);
            return (
              <LiElement
                key={entity.id}
                entity={entity}
                data={{
                  title: entity?.name + " " + entity?.surname,
                  detail: entity?.email,
                  avatarURL: entity?.avatarURL,
                }}
                disabled={disabled}
                actionButton={() => {
                  if (!disabled) return null;
                  return (
                    <Badge size={"sm"} variant={"outline"}>
                      Dziedziczone
                    </Badge>
                  );
                }}
              />
            );
          })}
        </SelectDataList>
      </DialogBody>
      <DialogFooter></DialogFooter>
    </>
  );
}

function ShareFSEntryGroupsSelectedDataList(
  props: ShareFSEntrySelectedDataListProps<GetPermissionsGroupI>,
) {
  const { setTab, selected, open, setSelected } = props;

  const [searchGroupValue, setSearchGroupValue] = useState<string>("");
  const searchGroupDebouncedValue = useDebounceValue(searchGroupValue, 400);

  const getGroupsInfiniteQuery = useGetGroupsInfiniteQuery({
    name: searchGroupDebouncedValue,
    enabled: open,
  });

  const {
    data: groups,
    isEmpty: groupsIsEmpty,
    noResults: groupsNoResults,
  } = useInfiniteQueryResult(
    getGroupsInfiniteQuery.data,
    searchGroupDebouncedValue,
  );

  const setSelectedCb = useCallback(
    (selected: any[]) => {
      setSelected(() => {
        if (selected) {
          return selected.map((group): GetPermissionsGroupI => {
            if ("permissions" in group) {
              return group as GetPermissionsGroupI;
            }
            return {
              ...group,
              permissions: {
                read: true,
                write: false,
                edit: false,
              },
            } as GetPermissionsGroupI;
          });
        }
        return [];
      });
    },
    [setSelected],
  );

  return (
    <>
      <DialogBody className="h-auto">
        <header className={"flex gap-3 py-4"}>
          <Button
            variant={"outline"}
            variantColor={"muted"}
            icon={<ChevronLeft />}
            iconPosition={"only"}
            onClick={() => {
              setTab("main_menu");
            }}
          />

          <Input
            placeholder={"Wyszukaj..."}
            value={searchGroupValue}
            onChange={(e) => setSearchGroupValue(e.target.value)}
            startContent={<Search className={"ml-2"} />}
            endContent={
              <InputActionButton
                disabled={!searchGroupValue.length}
                onClick={() => setSearchGroupValue("")}
                icon={<X />}
              />
            }
          />
        </header>
        <SelectDataList
          query={getGroupsInfiniteQuery}
          selected={selected}
          setSelected={setSelectedCb}
          isEmpty={groupsIsEmpty}
          isNoResults={groupsNoResults}
          emptyMessage={"Wyszukaj grupy"}
          searchNoResultsMessage={"Nie znaleziono grupy o podanej nazwie"}
          loadingElementPros={{
            hasAvatar: true,
            hasDetail: false,
            hasTitle: true,
          }}
        >
          {groups.map((entity) => (
            <LiElement
              key={entity.id}
              entity={entity}
              data={{
                title: entity.name,
                detail: entity.users.length + " użytkownik/ów",
                avatarURL: "",
              }}
            />
          ))}
          {groupsIsEmpty &&
            selected.map((entity) => (
              <LiElement
                key={entity.id}
                entity={entity}
                data={{
                  title: entity.name,
                  avatarURL: "",
                }}
              />
            ))}
        </SelectDataList>
      </DialogBody>
      <DialogFooter></DialogFooter>
    </>
  );
}
