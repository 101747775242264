import { cn } from "@/lib/utils";
import * as AvatarPrimitive from "@radix-ui/react-avatar";
import { VariantProps, cva } from "class-variance-authority";
import React from "react";

const colorVariants = {
  brand: "ring-fg-brand",
  accent: "ring-fg-accent",
  muted: "ring-fg-muted",
  success: "ring-fg-success",
  warning: "ring-fg-warning",
  destructive: "ring-fg-destructive",
};

const avatarVariants = cva(
  "relative overflow-hidden bg-bg-element box-border flex flex-shrink-0 border-1 border-border",
  {
    variants: {
      variantColor: {
        brand: "",
        accent: "",
        muted: "",
        success: "",
        warning: "",
        destructive: "",
      },
      size: {
        sm: "max-h-10 h-10 min-h-10 min-w-10 w-10 max-w-10 text-xs rounded-sm [&>svg]:w-4 [&>svg]:h-4",
        md: "max-h-11 h-11 min-h-11 min-w-11 w-11 max-w-11 text-sm rounded-md [&>svg]:w-5 [&>svg]:h-5",
        lg: "max-h-12 h-12 min-h-12 min-w-12 w-12 max-w-12 text-md rounded-lg [&>svg]:w-6 [&>svg]:h-6",
      },
      isDisabled: {
        true: "opacity-60 pointer-events-none",
      },
      isBordered: {
        true: "ring-2 ring-offset-2 ring-offset-bg-container",
        false: "",
      },
    },

    defaultVariants: {
      size: "md",
      isBordered: false,
    },

    compoundVariants: [
      {
        variantColor: "muted",
        isBordered: true,
        className: colorVariants.muted,
      },
      {
        variantColor: "brand",
        isBordered: true,
        className: colorVariants.brand,
      },
      {
        variantColor: "accent",
        isBordered: true,
        className: colorVariants.accent,
      },
      {
        variantColor: "success",
        isBordered: true,
        className: colorVariants.success,
      },
      {
        variantColor: "warning",
        isBordered: true,
        className: colorVariants.warning,
      },
      {
        variantColor: "destructive",
        isBordered: true,
        className: colorVariants.destructive,
      },
    ],
  },
);

interface AvatarProps
  extends React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root>,
    VariantProps<typeof avatarVariants> {
  className?: string;
}

const Avatar = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Root>,
  AvatarProps
>(({ className, size, isBordered, variantColor, ...props }, ref) => (
  <AvatarPrimitive.Root
    ref={ref}
    className={cn(
      avatarVariants({ size, isBordered, variantColor }),
      className,
    )}
    {...props}
  />
));
Avatar.displayName = AvatarPrimitive.Root.displayName;

const AvatarImage = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Image>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image>
>(({ className, src, ...props }, ref) => (
  <AvatarPrimitive.Image
    ref={ref}
    className={cn("aspect-square h-full w-full", className)}
    src={src ? src : undefined}
    {...props}
  />
));
AvatarImage.displayName = AvatarPrimitive.Image.displayName;

const AvatarFallback = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Fallback>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Fallback
    ref={ref}
    className={cn(
      "flex h-full w-full items-center justify-center text-fg-muted",
      className,
    )}
    {...props}
  />
));
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName;

export { Avatar, AvatarImage, AvatarFallback, avatarVariants };
