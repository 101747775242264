import { cn } from "@/lib/utils";
import { ChevronRight, MoreHorizontal } from "lucide-react";
import * as React from "react";
import { Link } from "react-router-dom";

const Breadcrumb = React.forwardRef<
  HTMLElement,
  React.ComponentPropsWithoutRef<"nav"> & {
    separator?: React.ReactNode;
  }
>(({ ...props }, ref) => (
  <nav ref={ref} aria-label={"breadcrumb"} {...props} />
));
Breadcrumb.displayName = "Breadcrumb";

const BreadcrumbList = React.forwardRef<
  HTMLOListElement,
  React.ComponentPropsWithoutRef<"ol">
>(({ className, ...props }, ref) => (
  <ol
    ref={ref}
    className={cn(
      "flex flex-wrap items-center break-words text-sm text-fg-muted",
      className,
    )}
    {...props}
  />
));
BreadcrumbList.displayName = "BreadcrumbList";

const BreadcrumbItem = React.forwardRef<
  HTMLLIElement,
  React.ComponentPropsWithoutRef<"li">
>(({ className, ...props }, ref) => (
  <li
    ref={ref}
    className={cn(
      "inline-flex h-10 shrink-0 cursor-pointer items-center gap-1",
      className,
    )}
    {...props}
  />
));
BreadcrumbItem.displayName = "BreadcrumbItem";

const BreadcrumbLink = React.forwardRef<
  HTMLAnchorElement,
  React.ComponentPropsWithoutRef<"a"> & {
    asChild?: boolean;
    href: string;
  }
>(({ className, href, ...props }, ref) => {
  return (
    <Link
      ref={ref}
      to={href}
      className={cn(
        "flex h-full w-full items-center justify-center rounded-sm px-3 transition-all duration-200 hover:bg-bg-muted-subtle/50 hover:text-fg-primary",
        className,
      )}
      {...props}
    />
  );
});
BreadcrumbLink.displayName = "BreadcrumbLink";

const BreadcrumbPage = React.forwardRef<
  HTMLButtonElement,
  React.ComponentPropsWithoutRef<"button">
>(({ className, ...props }, ref) => (
  <button
    ref={ref}
    role={"link"}
    aria-disabled={"true"}
    aria-current={"page"}
    className={cn(
      "h-full rounded-sm px-3 font-normal text-fg-primary transition-all duration-200 hover:bg-bg-muted-subtle/50",
      className,
    )}
    {...props}
  />
));
BreadcrumbPage.displayName = "BreadcrumbPage";

const BreadcrumbSeparator = ({
  children,
  className,
  ...props
}: React.ComponentProps<"li">) => (
  <li
    role={"presentation"}
    aria-hidden={"true"}
    className={cn("[&>svg]:size-4", className)}
    {...props}
  >
    {children ?? <ChevronRight />}
  </li>
);
BreadcrumbSeparator.displayName = "BreadcrumbSeparator";

const BreadcrumbEllipsis = ({
  className,
  ...props
}: React.ComponentProps<"span">) => (
  <span
    role={"presentation"}
    aria-hidden={"true"}
    className={cn(
      "flex h-10 w-10 shrink-0 items-center justify-center",
      className,
    )}
    {...props}
  >
    <MoreHorizontal className={"h-4 w-4"} />
    <span className={"sr-only"}>More</span>
  </span>
);
BreadcrumbEllipsis.displayName = "BreadcrumbElipssis";

export {
  Breadcrumb,
  BreadcrumbList,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbPage,
  BreadcrumbSeparator,
  BreadcrumbEllipsis,
};
