import {
  Button,
  ButtonPropsWithHTMLAttributes,
} from "@/components/ui/button/Button";
import { cn } from "@/lib/utils";
import { cva } from "class-variance-authority";
import { X } from "lucide-react";
import * as React from "react";
import { useMemo } from "react";

const InputActionButton = React.forwardRef<
  HTMLButtonElement,
  ButtonPropsWithHTMLAttributes
>(({ onClick, icon = <X />, ...props }, ref) => {
  const handleOnClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    if (onClick) onClick(event);
  };

  return (
    <Button
      ref={ref}
      size={"sm"}
      variant={"ghost"}
      variantColor={"muted"}
      iconPosition={"only"}
      icon={icon}
      className={"h-9 min-h-9 w-9 min-w-9 flex-shrink-0"}
      tabIndex={-1}
      onClick={handleOnClick}
      {...props}
    />
  );
});
InputActionButton.displayName = "InputActionButton";

const InputVariants = cva(
  "inline-flex items-center min-h-11 h-11 w-full px-4 py-1 gap-2 rounded-md border-1 border-border hover:bg-bg-element/20 bg-bg-container text-md text-fg-primary [&:has(:focus-visible)]:outline [&:has(:focus-visible)]:outline-offset-2 [&:has(:focus-visible)]:outline-2 [&:has(:focus-visible)]:outline-ring  [&>:disabled]:opacity-50 transition-color transition-opacity duration-100 [&>svg]:stroke-fg-muted [&>svg]:flex-shrink-0 [&>svg]:w-4 [&>svg]:h-4",
  {
    variants: {
      startPadding: {
        true: "pl-1",
        false: "",
      },
      endPadding: {
        true: "pr-1",
        false: "",
      },
    },
    compoundVariants: [
      { startPadding: true, endPadding: true, className: "px-1" },
    ],

    defaultVariants: {
      startPadding: false,
      endPadding: false,
    },
  },
);

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  onClear?: () => void;
  suffixContent?: string;
  prefixContent?: string;
  startContent?: React.ReactElement;
  endContent?: React.ReactElement;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      type,
      onClear,
      prefixContent,
      suffixContent,
      startContent,
      endContent,
      ...props
    },
    ref,
  ) => {
    const inputHtmlElementRef = React.useRef<HTMLInputElement>();

    function handleFocusInput() {
      if (inputHtmlElementRef.current) inputHtmlElementRef.current.focus();
    }
    function mergeRefs<T = any>(
      refs: Array<
        React.MutableRefObject<T> | React.LegacyRef<T> | undefined | null
      >,
    ): React.RefCallback<T> {
      return (value) => {
        refs.forEach((ref) => {
          if (typeof ref === "function") {
            ref(value);
          } else if (ref != null) {
            (ref as React.MutableRefObject<T | null>).current = value;
          }
        });
      };
    }

    function clearInput() {
      if (onClear) onClear();
      handleFocusInput();
    }

    const startJSXElement = useMemo(() => {
      return startContent || null;
    }, [startContent]);
    const endJSXElement = useMemo(() => {
      if (onClear) return <InputActionButton onClick={clearInput} />;
      return endContent || null;
    }, [endContent, onClear]);

    return (
      <div
        className={cn(
          InputVariants({
            startPadding: !!startJSXElement,
            endPadding: !!endJSXElement,
          }),
          type === "file" ? "cursor-pointer" : "cursor-text",
          className,
        )}
        onClick={handleFocusInput}
      >
        {startJSXElement}
        {prefixContent && (
          <small className={"text-xs text-fg-muted"}>{prefixContent}</small>
        )}
        <input
          type={type}
          className={cn(
            "flex h-full w-full items-center bg-transparent text-fg-primary placeholder:text-fg-muted",
            "file:cursor-pointer file:border-0 file:bg-transparent file:text-sm file:font-medium",
          )}
          ref={mergeRefs([ref, inputHtmlElementRef])}
          {...props}
        />
        {suffixContent && (
          <small className={"text-xs text-fg-muted"}>{suffixContent}</small>
        )}
        {endJSXElement}
      </div>
    );
  },
);
Input.displayName = "Input";

export { Input, InputActionButton, InputVariants };
