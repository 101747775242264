import CreateFSDir from "@/components/features/files/forms/CreateFSDir";
import DeleteFSEntry from "@/components/features/files/forms/DeleteFSEntry";
import DownloadFSEntry from "@/components/features/files/forms/DownloadFSEntry";
import EditFSEntryName from "@/components/features/files/forms/EditFSEntryName";
import InfoFSEntry from "@/components/features/files/forms/InfoFSEntry";
import MoveFSEntry from "@/components/features/files/forms/MoveFSEntry";
import ShareFSEntry from "@/components/features/files/forms/ShareFSEntry";
import { useFilesStorage } from "@/store/filesStore";
import { DirectoryElementsI } from "@/types/files";
import { Fragment } from "react/jsx-runtime";

type FilePageFormsProps = {
  directoryId: string;
};

export default function FilePageForms({ directoryId }: FilePageFormsProps) {
  const { selected, resetSelected } = useFilesStorage();

  return (
    <FileForms
      elements={selected}
      directoryId={directoryId}
      resetSelected={resetSelected}
    />
  );
}

type FileFormsProps = {
  directoryId: string;
  elements: DirectoryElementsI;
  resetSelected?: () => void;
};

export function FileForms(props: FileFormsProps) {
  const { directoryId, elements, resetSelected } = props;

  const {
    openDelete,
    openDownload,
    openEditName,
    openShare,
    openMove,
    openMoreInfo,
    openCreateDir,
    setOpenCreateDir,
    setOpenDelete,
    setOpenDownload,
    setOpenEditName,
    setOpenMoreInfo,
    setOpenMove,
    setOpenShare,
  } = useFilesStorage();

  return (
    <Fragment>
      <CreateFSDir
        parentDirId={directoryId}
        open={openCreateDir}
        onOpenChange={setOpenCreateDir}
      />
      <EditFSEntryName
        open={openEditName}
        onOpenChange={setOpenEditName}
        element={elements.directories[0] || elements.files[0]}
        directoryId={directoryId}
      />
      <InfoFSEntry
        open={openMoreInfo}
        onOpenChange={setOpenMoreInfo}
        element={elements.directories[0] || elements.files[0]}
      />
      <MoveFSEntry
        open={openMove}
        onOpenChange={setOpenMove}
        elements={elements}
        submitCallback={resetSelected}
        directoryId={directoryId}
      />
      <DeleteFSEntry
        open={openDelete}
        onOpenChange={setOpenDelete}
        elements={elements}
        submitCallback={resetSelected}
        directoryId={directoryId}
      />
      <DownloadFSEntry
        open={openDownload}
        onOpenChange={setOpenDownload}
        elements={elements}
        submitCallback={resetSelected}
      />
      <ShareFSEntry
        open={openShare}
        onOpenChange={setOpenShare}
        element={elements.directories[0] || elements.files[0]}
        submitCallback={resetSelected}
        directoryId={directoryId}
      />
    </Fragment>
  );
}
