import {
  CalendarDate,
  CalendarDateTime,
  DateValue,
  getLocalTimeZone,
  startOfYear,
  today,
} from "@internationalized/date";
import { useCallback, useMemo } from "react";
import { useDateFormatter } from "react-aria";
import { date } from "yup";

function addMonths(date: DateValue, months: number) {
  return date.add({ months });
}

type useGetYearRangeProps = {
  timeZone: string;
  minValue: DateValue | null | undefined;
  maxValue: DateValue | null | undefined;
};

function useGetYearRange(props: useGetYearRangeProps) {
  const { timeZone, minValue, maxValue } = props;

  const currentDate = useMemo(() => today(timeZone), [timeZone]);
  const startDate = useMemo(
    () => minValue || currentDate.subtract({ years: 100 }),
    [currentDate, minValue],
  );
  const endDate = useMemo(
    () => maxValue || currentDate.add({ years: 100 }),
    [currentDate, maxValue],
  );

  const yearDateFormatter = useDateFormatter({
    year: "numeric",
    timeZone: timeZone,
  });

  const validStart = useMemo(
    () => (startDate.compare(endDate) <= 0 ? startDate : endDate),
    [endDate, startDate],
  );
  const validEnd = useMemo(
    () => (startDate.compare(endDate) <= 0 ? endDate : startDate),
    [endDate, startDate],
  );

  const dates: { date: DateValue; value: number; label: string }[] = [];
  let current = validStart;

  while (current.compare(validEnd) <= 0) {
    dates.push({
      date: current,
      value: current.year,
      label: yearDateFormatter.format(current.toDate(timeZone)),
    });
    current = current.add({ years: 1 });
  }

  return dates;
}

type useGetMonthsInYearProps = {
  timeZone: string;
  currentMonth: DateValue;
};

function useGetMonthsInYear(props: useGetMonthsInYearProps) {
  const { currentMonth, timeZone } = props;
  const firstMonth = startOfYear(currentMonth);

  const monthDateFormatter = useDateFormatter({
    month: "long",

    timeZone: timeZone,
  });

  return useMemo(() => {
    const months = [];
    for (let month = 0; month < 12; month++) {
      const newMonth = addMonths(firstMonth, month);
      newMonth.set({ year: currentMonth.year });
      months.push({
        date: newMonth,
        value: newMonth.month,
        label: monthDateFormatter.format(newMonth.toDate(timeZone)),
      });
    }
    return months;
  }, [currentMonth.year, firstMonth, monthDateFormatter, timeZone]);
}

type isDisabledProps = {
  minValue: DateValue | null | undefined;
  maxValue: DateValue | null | undefined;
};
function useIsDisabled(props: isDisabledProps) {
  const { minValue, maxValue } = props;

  const isDisabled = useCallback(
    (date: DateValue) => {
      const min = minValue || false;
      const max = maxValue || false;

      if (min && date.compare(min) < 0) {
        return true;
      }
      if (max && date.compare(max) > 0) {
        return true;
      }
      return false;
    },
    [minValue, maxValue],
  );

  return { isDisabled };
}

function dateToCalendarDate(date?: Date): CalendarDate | undefined {
  if (!date) return undefined;
  return new CalendarDate(date.getFullYear(), date.getMonth(), date.getDate());
}

function dateToCalendarDateTime(date?: Date): CalendarDateTime | undefined {
  if (!date) return undefined;
  return new CalendarDateTime(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
  );
}

function dateValueToDate(dateValue: DateValue, timeZone?: string): Date {
  return dateValue.toDate(timeZone || getLocalTimeZone());
}

function getToday(timeZone?: string): CalendarDate {
  return today(timeZone || getLocalTimeZone());
}

export {
  getToday,
  useIsDisabled,
  useGetYearRange,
  useGetMonthsInYear,
  dateToCalendarDate,
  dateToCalendarDateTime,
  dateValueToDate,
};
