import { Button, buttonVariants } from "@/components/ui/button/Button";
import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
} from "@/components/ui/drawer/Drawer";
import {
  InputActionButton,
  InputProps,
  InputVariants,
} from "@/components/ui/input/Input";
import {
  useGetMonthsInYear,
  useGetYearRange,
  useIsDisabled,
} from "@/components/ui/input/datetime-picker/useDatetimePicker";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover/Popover";
import { ScrollArea } from "@/components/ui/scroll-area/ScrollArea";
import useMediaQueryHook from "@/hooks/useMediaQueryHook";
import { cn } from "@/lib/utils";
import {
  CalendarDate,
  DateValue,
  ZonedDateTime,
  createCalendar,
  fromDate,
  getDayOfWeek,
  getLocalTimeZone,
  isSameDay,
  isToday,
  parseTime,
  toCalendarDate,
  toCalendarDateTime,
} from "@internationalized/date";
import {
  CalendarPropsBase,
  SpectrumCalendarProps,
} from "@react-types/calendar";
import {
  AriaDatePickerProps,
  AriaTimeFieldProps,
  DatePickerBase,
  SpectrumDatePickerProps,
} from "@react-types/datepicker";
import { DOMProps } from "@react-types/shared";
import {
  CalendarIcon,
  ChevronDown,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from "lucide-react";
import React, {
  HTMLAttributes,
  ReactElement,
  isValidElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AriaButtonProps,
  AriaCalendarCellProps,
  TimeValue,
  useButton,
  useCalendar,
  useCalendarCell,
  useCalendarGrid,
  useDateField,
  useDateFormatter,
  useDatePicker,
  useDateSegment,
  useLocale,
  useTimeField,
} from "react-aria";
import {
  CalendarState,
  DateFieldState,
  DateSegment,
  RangeCalendarState,
  useCalendarState,
  useDateFieldState,
  useDatePickerState,
  useTimeFieldState,
} from "react-stately";

interface MonthYearPickerProps<T extends CalendarState | RangeCalendarState>
  extends CalendarPropsBase,
    DOMProps {
  state: T;
  currentMonth: CalendarDate;
}

function MonthYearPicker<T extends CalendarState | RangeCalendarState>(
  props: MonthYearPickerProps<T>,
) {
  const { state, currentMonth } = props;
  const yearRef = useRef<HTMLDivElement>(null);

  const [mode, setMode] = useState<"month" | "year" | false>(false);

  useEffect(() => {
    if (mode === "year") {
      yearRef.current?.scrollIntoView({ behavior: "auto", block: "center" });
    }
  }, [mode]);

  const yearFormatted = useDateFormatter({
    year: "numeric",
    timeZone: state.timeZone,
  });
  const monthFormatted = useDateFormatter({
    month: "short",
    timeZone: state.timeZone,
  });

  const { isDisabled } = useIsDisabled({
    minValue: state.minValue,
    maxValue: state.maxValue,
  });

  const years = useGetYearRange({
    timeZone: state.timeZone,
    minValue: state.minValue,
    maxValue: state.maxValue,
  });

  const months = useGetMonthsInYear({
    timeZone: state.timeZone,
    currentMonth: currentMonth,
  });

  const onYearChange = (newYear: number) => {
    const date = state.focusedDate.set({ year: newYear });
    state.setFocusedDate(date);
    setMode("month");
  };

  const onMonthChange = (newMonth: number) => {
    const date = state.focusedDate.set({ month: newMonth });
    state.setFocusedDate(date);
    setMode(false);
  };

  return (
    <>
      <div
        className={cn(
          buttonVariants({
            size: "sm",
            variant: "outline",
            variantColor: "muted",
          }),
          "w-full cursor-pointer gap-0 overflow-visible p-0",
        )}
      >
        <button
          type={"button"}
          onClick={() => setMode(mode === "month" ? false : "month")}
          className={cn(
            buttonVariants({
              size: "sm",
              variant: "ghost",
              variantColor: "muted",
              iconPosition: "none",
            }),
            "w-full px-1",
          )}
        >
          {monthFormatted.format(state.focusedDate.toDate(state.timeZone))}
        </button>
        <button
          type={"button"}
          onClick={() => setMode(mode === "year" ? false : "year")}
          className={cn(
            buttonVariants({
              size: "sm",
              variant: "ghost",
              variantColor: "muted",
              iconPosition: "none",
            }),
            "w-full rounded-r-none px-1",
          )}
        >
          {yearFormatted.format(state.focusedDate.toDate(state.timeZone))}
        </button>
        <span className="h-full w-px bg-bg-muted" />
        <button
          type={"button"}
          className={cn(
            buttonVariants({
              size: "sm",
              variant: "ghost",
              variantColor: "muted",
              iconPosition: "only",
            }),
            "rounded-l-none",
          )}
          onClick={() => setMode(mode ? false : "year")}
        >
          {mode ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </button>
      </div>
      <div
        data-open={mode}
        className={
          "absolute -bottom-1 -left-1 -right-1 top-11 z-20 data-[open=false]:hidden"
        }
      >
        <div className={cn("h-full grow bg-bg-container")}>
          <ScrollArea className={"h-full grow"}>
            {mode === "year" && (
              <div className={"grid grid-cols-4 p-1"}>
                {years.map((year) => (
                  <div
                    key={year.value}
                    ref={
                      year.value === state.focusedDate.year
                        ? yearRef
                        : undefined
                    }
                  >
                    <Button
                      size={"sm"}
                      disabled={isDisabled(year.date)}
                      variant={
                        state.focusedDate.year === year.value ? "flat" : "ghost"
                      }
                      variantColor={
                        state.focusedDate.year === year.value
                          ? "brand"
                          : "muted"
                      }
                      onClick={() => onYearChange(year.value)}
                      className={"px-3 font-normal"}
                    >
                      {year.label}
                    </Button>
                  </div>
                ))}
              </div>
            )}
            {mode === "month" && (
              <div className="grid grid-cols-3 gap-2 p-1">
                {months.map((month) => (
                  <Button
                    type={"button"}
                    key={month.value}
                    size={"sm"}
                    disabled={isDisabled(month.date)}
                    variant={
                      state.focusedDate.month === month.value ? "flat" : "ghost"
                    }
                    variantColor={
                      state.focusedDate.month === month.value
                        ? "brand"
                        : "muted"
                    }
                    className={"px-3 font-normal"}
                    onClick={() => onMonthChange(month.value)}
                  >
                    {month.label}
                  </Button>
                ))}
              </div>
            )}
          </ScrollArea>
        </div>
      </div>
    </>
  );
}

interface CalendarBaseProps<T extends CalendarState | RangeCalendarState>
  extends CalendarPropsBase,
    DOMProps {
  state: T;
  visibleMonths?: number;
  calendarProps: HTMLAttributes<HTMLElement>;
  nextButtonProps: AriaButtonProps;
  prevButtonProps: AriaButtonProps;
}

function CalendarBase<T extends CalendarState | RangeCalendarState>(
  props: CalendarBaseProps<T>,
) {
  const {
    state,
    nextButtonProps: _nextButtonProps,
    prevButtonProps: _prevButtonProps,
    visibleMonths = 1,
  } = props;

  const prevButtonRef = useRef<HTMLButtonElement | null>(null);
  const nextButtonRef = useRef<HTMLButtonElement | null>(null);

  const { buttonProps: prevButtonProps } = useButton(
    _prevButtonProps,
    prevButtonRef,
  );
  const { buttonProps: nextButtonProps } = useButton(
    _nextButtonProps,
    nextButtonRef,
  );

  const currentMonth = state.visibleRange.start;

  const titles: JSX.Element[] = [];
  const calendars: JSX.Element[] = [];

  for (let i = 0; i < visibleMonths; i++) {
    const d = currentMonth.add({ months: i });
    titles.push(
      <div key={i} className={"flex h-10 items-center justify-between gap-4"}>
        {i === 0 && (
          <Button
            {...prevButtonProps}
            ref={nextButtonRef}
            type={"button"}
            size={"sm"}
            variant={"outline"}
            variantColor={"muted"}
            iconPosition={"only"}
            icon={<ChevronLeftIcon />}
          />
        )}
        {/* <h2 aria-hidden className={"text-sm font-medium text-fg-primary"}>
          {monthDateFormatter.format(d.toDate(state.timeZone))}
        </h2> */}
        <MonthYearPicker state={state} currentMonth={d} />

        {i === visibleMonths - 1 && (
          <Button
            {...nextButtonProps}
            ref={nextButtonRef}
            size={"sm"}
            variant={"outline"}
            variantColor={"muted"}
            iconPosition={"only"}
            icon={<ChevronRightIcon />}
          />
        )}
      </div>,
    );

    calendars.push(
      <CalendarMonth {...props} key={i} state={state} startDate={d} />,
    );
  }

  return (
    <div>
      <div>{titles}</div>
      <div>{calendars}</div>
    </div>
  );
}

interface CalendarMonthProps extends CalendarPropsBase, DOMProps {
  state: CalendarState | RangeCalendarState;
  startDate: CalendarDate;
}

function CalendarMonth(props: CalendarMonthProps) {
  const { state, startDate } = props;
  const { weekDays } = useCalendarGrid(
    {
      ...props,
    },
    state,
  );

  return (
    <table className={"w-full border-collapse"}>
      <thead>
        <tr className={"flex py-2"}>
          {weekDays.map((day, index) => (
            <th
              key={index}
              className={"w-10 rounded-md text-xs font-normal text-fg-muted"}
            >
              {day}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {[...new Array(6).keys()].map((weekIndex) => (
          <tr className={"flex w-full"} key={weekIndex}>
            {state
              .getDatesInWeek(weekIndex, startDate)
              .map((date, i) =>
                date ? (
                  <CalendarCell
                    key={i}
                    state={state}
                    date={date}
                    currentMonth={startDate}
                  />
                ) : (
                  <td key={i} />
                ),
              )}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

interface CalendarCellProps extends AriaCalendarCellProps {
  state: CalendarState | RangeCalendarState;
  currentMonth: CalendarDate;
}

function CalendarCell({ state, currentMonth, ...props }: CalendarCellProps) {
  const ref = React.useRef<HTMLButtonElement | null>(null);
  const {
    cellProps,
    buttonProps,
    isSelected,
    isOutsideVisibleRange,
    isDisabled,
    isInvalid,
    formattedDate,
  } = useCalendarCell({ ...props }, state, ref);

  const isTodayDate = isToday(props.date, state.timeZone);
  const isUnavailable = state.isCellUnavailable(props.date) && !isDisabled;
  const isLastSelectedBeforeDisabled =
    !isDisabled &&
    !isInvalid &&
    state.isCellUnavailable(props.date.add({ days: 1 }));
  const isFirstSelectedAfterDisabled =
    !isDisabled &&
    !isInvalid &&
    state.isCellUnavailable(props.date.subtract({ days: 1 }));
  const highlightedRange =
    "highlightedRange" in state && state.highlightedRange;
  const isSelectionStart =
    isSelected &&
    highlightedRange &&
    isSameDay(props.date, highlightedRange.start);
  const isSelectionEnd =
    isSelected &&
    highlightedRange &&
    isSameDay(props.date, highlightedRange.end);
  const { locale } = useLocale();
  const dayOfWeek = getDayOfWeek(props.date, locale);
  const isRangeStart =
    isSelected &&
    (isFirstSelectedAfterDisabled || dayOfWeek === 0 || props.date.day === 1);
  const isRangeEnd =
    isSelected &&
    (isLastSelectedBeforeDisabled ||
      dayOfWeek === 6 ||
      props.date.day === currentMonth.calendar.getDaysInMonth(currentMonth));

  return (
    <td
      {...cellProps}
      className={cn(
        cellProps.className,
        "relative p-0 text-center text-sm focus-within:relative focus-within:z-10 [&:has([aria-selected])]:bg-bg-brand-subtle first:[&:has([aria-selected])]:rounded-l-sm last:[&:has([aria-selected])]:rounded-r-sm",
      )}
    >
      <Button
        {...buttonProps}
        type={"button"}
        variant={"ghost"}
        variantColor={"muted"}
        size={"sm"}
        ref={ref}
        className={cn(
          "h-8 w-8",
          isTodayDate && "bg-bg-muted-subtle",
          isUnavailable && "cursor-default text-fg-muted opacity-10",
          isOutsideVisibleRange && "text-fg-muted opacity-50",
          isDisabled && "text-fg-muted opacity-50",
          isSelected && "bg-bg-brand-subtle text-fg-brand",
        )}
      >
        {formattedDate}
      </Button>
    </td>
  );
}

function Calendar<T extends DateValue>(props: SpectrumCalendarProps<T>) {
  const { visibleMonths = 1 } = props;
  const maxVisibleMonths = Math.max(visibleMonths, 1);
  const visibleDuration = useMemo(
    () => ({ months: maxVisibleMonths }),
    [maxVisibleMonths],
  );

  const { locale } = useLocale();
  const state = useCalendarState({
    ...props,
    locale,
    visibleDuration,
    createCalendar,
  });
  const { calendarProps, prevButtonProps, nextButtonProps } = useCalendar(
    props,
    state,
  );

  return (
    <CalendarBase
      {...props}
      visibleMonths={maxVisibleMonths}
      state={state}
      calendarProps={calendarProps}
      prevButtonProps={prevButtonProps}
      nextButtonProps={nextButtonProps}
    />
  );
}

interface DatePickerSegmentProps extends DatePickerBase<DateValue> {
  segment: DateSegment;
  state: DateFieldState;
}
interface LiteralSegmentProps {
  segment: DateSegment;
}

function DatePickerSegment({
  segment,
  state,
  ...otherProps
}: DatePickerSegmentProps) {
  switch (segment.type) {
    // A separator, e.g. punctuation
    case "literal":
      return <LiteralSegment segment={segment} />;

    // Editable segment
    default:
      return (
        <EditableSegment segment={segment} state={state} {...otherProps} />
      );
  }
}

function LiteralSegment({ segment }: LiteralSegmentProps) {
  return <span aria-hidden="true">{segment.text}</span>;
}

function EditableSegment({ segment, state }: DatePickerSegmentProps) {
  const ref = useRef<HTMLDivElement>(null);
  const { segmentProps } = useDateSegment(segment, state, ref);
  return (
    <div
      ref={ref}
      className={cn(
        "truncate rounded-xs text-md focus:bg-bg-brand-subtle focus:text-fg-brand focus:outline-none",
        segment.type !== "literal" && "px-0.5",
        segment.isPlaceholder && "text-fg-muted",
      )}
      style={segmentProps.style}
      {...segmentProps}
    >
      {segment.isPlaceholder ? (
        <span aria-hidden="true">{segment.placeholder}</span>
      ) : (
        segment.text
      )}
    </div>
  );
}

interface DatePickerFieldProps<T extends DateValue>
  extends SpectrumDatePickerProps<T> {
  inputClassName?: string;
  hideValidationIcon?: boolean;
  maxGranularity?: SpectrumDatePickerProps<T>["granularity"];
}

function DatePickerField<T extends DateValue>(props: DatePickerFieldProps<T>) {
  const { isDisabled, isReadOnly, isRequired, inputClassName } = props;
  const inputRef = useRef<HTMLInputElement | null>(null);
  const ref = useRef<HTMLDivElement | null>(null);

  const { locale } = useLocale();
  const state = useDateFieldState({
    ...props,
    locale,
    createCalendar,
  });
  const { fieldProps, inputProps } = useDateField(
    { ...props, inputRef },
    state,
    ref,
  );
  return (
    <div
      {...fieldProps}
      ref={ref}
      className={cn(
        "border-input ring-offset-background inline-flex h-10 flex-1 items-center rounded-l-md bg-transparent text-sm focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
        props.isDisabled && "cursor-not-allowed opacity-50",
        inputClassName,
      )}
    >
      {state.segments.map((segment, i) => (
        <DatePickerSegment
          key={i}
          segment={segment}
          state={state}
          isDisabled={isDisabled}
          isReadOnly={isReadOnly}
          isRequired={isRequired}
        />
      ))}
      {state.isInvalid && <span aria-hidden={"true"}>🚫</span>}
      <input {...inputProps} ref={inputRef} />
    </div>
  );
}

export function TimeField<T extends TimeValue>(
  props: AriaTimeFieldProps<T> & Omit<InputProps, "value" | "onChange">,
) {
  const { isDisabled, isReadOnly, isRequired, startContent, endContent } =
    props;

  const { locale } = useLocale();
  const state = useTimeFieldState({
    ...props,
    locale,
  });

  const fieldRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const { fieldProps, inputProps } = useTimeField(
    {
      ...props,
      inputRef,
    },
    state,
    fieldRef,
  );

  const startJSXElement = useMemo(() => {
    return startContent || null;
  }, [startContent]);
  const endJSXElement = useMemo(() => {
    return endContent || null;
  }, [endContent]);

  return (
    <div
      ref={fieldRef}
      {...fieldProps}
      className={cn(
        InputVariants({
          startPadding: !!startJSXElement,
          endPadding: !!endJSXElement,
        }),
        props.isDisabled && "cursor-not-allowed opacity-50",
      )}
    >
      {startJSXElement}
      {state.segments.map((segment, i) => (
        <DatePickerSegment
          key={i}
          segment={segment}
          state={state}
          isDisabled={isDisabled}
          isReadOnly={isReadOnly}
          isRequired={isRequired}
        />
      ))}
      <input {...inputProps} ref={inputRef} />
      {endJSXElement}
    </div>
  );
}

type DatePickerStateOptions = Omit<
  AriaDatePickerProps<ZonedDateTime>,
  "minValue" | "maxValue" | "value" | "onChange"
> & {
  button?: ReactElement | boolean;
  className?: string;
  minValue?: Date;
  maxValue?: Date;
  value?: Date | null;

  onChange?: (date: Date | null) => void;
};

export function DatetimePicker(defaultProps: DatePickerStateOptions) {
  const {
    className,
    button,
    value,
    onChange,
    minValue,
    maxValue,
    placeholderValue,
    ...props
  } = defaultProps;
  const breakpoint = useMediaQueryHook("sm");
  const { locale } = useLocale();
  const targetRef = useRef<HTMLDivElement>(null);
  const state = useDatePickerState({
    value: value ? fromDate(value, getLocalTimeZone()) : undefined,
    minValue: minValue ? fromDate(minValue, getLocalTimeZone()) : undefined,
    maxValue: maxValue ? fromDate(maxValue, getLocalTimeZone()) : undefined,
    ...props,
  });

  const { groupProps, fieldProps, dialogProps, calendarProps } = useDatePicker(
    {
      value: value ? fromDate(value, getLocalTimeZone()) : undefined,
      minValue: minValue ? fromDate(minValue, getLocalTimeZone()) : undefined,
      maxValue: maxValue ? fromDate(maxValue, getLocalTimeZone()) : undefined,
      ...props,
    },
    state,
    targetRef,
  );

  const onValueChange = (newValue: DateValue): void => {
    let date = null;
    if (newValue) {
      date = newValue.toDate(getLocalTimeZone());
    }
    console.log(date);
    onChange?.(date);
    fieldProps.onChange?.(newValue);
    calendarProps.onChange?.(newValue);
  };
  const currentValue = useCallback((): DateValue | null | undefined => {
    if (!value) {
      return state?.value;
    }

    const parsed = fromDate(value, getLocalTimeZone());
    if (state.hasTime) {
      return toCalendarDateTime(parsed);
    }

    return toCalendarDate(parsed);
  }, [value, state.hasTime, state.value]);

  const placeholder: DateValue | undefined = placeholderValue;
  const timePlaceholder =
    placeholder && "hour" in placeholder ? placeholder : undefined;
  const timeMinValue =
    minValue && "hour" in minValue
      ? parseTime(minValue.toISOString())
      : undefined;
  const timeMaxValue =
    maxValue && "hour" in maxValue
      ? parseTime(maxValue.toISOString())
      : undefined;
  const timeGranularity =
    state.granularity === "hour" ||
    state.granularity === "minute" ||
    state.granularity === "second"
      ? state.granularity
      : undefined;
  const showTimeField = !!timeGranularity;

  let trigger;

  const dateString = useMemo(() => {
    if (!state.dateValue) {
      return "Wybierz datę";
    }

    const options: Intl.DateTimeFormatOptions = {};

    switch (state.granularity) {
      case "day":
        options.year = "numeric";
        options.month = "short";
        options.day = "numeric";
        break;
      case "hour":
        options.year = "numeric";
        options.month = "short";
        options.day = "numeric";
        options.hour = "numeric";
        break;
      case "minute":
        options.year = "numeric";
        options.month = "short";
        options.day = "numeric";
        options.hour = "numeric";
        options.minute = "numeric";
        break;
      case "second":
        options.year = "numeric";
        options.month = "short";
        options.day = "numeric";
        options.hour = "numeric";
        options.minute = "numeric";
        options.second = "numeric";
        break;
      default:
        options.dateStyle = "medium";
        options.timeStyle = "short";
    }

    const formatter = new Intl.DateTimeFormat(locale, options);
    return formatter.format(
      new Date(state.dateValue.toDate(getLocalTimeZone())),
    );
  }, [locale, state.dateValue, state.granularity]);

  if (isValidElement(button)) {
    trigger = button;
  } else if (button === true) {
    trigger = (
      <Button
        className={cn(className)}
        variant={"outline"}
        variantColor={"muted"}
        iconPosition={"right"}
        icon={<ChevronDown />}
      >
        {dateString}
      </Button>
    );
  } else {
    trigger = (
      <InputActionButton icon={<CalendarIcon />} iconPosition={"only"} />
    );
  }

  const content = breakpoint ? (
    <Drawer open={state.isOpen} onOpenChange={state.setOpen}>
      <DrawerTrigger asChild>{trigger}</DrawerTrigger>
      <DrawerContent {...dialogProps}>
        <div className={"flex w-full justify-center pb-4"}>
          <div className={"relative space-y-3"}>
            <Calendar {...calendarProps} onChange={onValueChange} />
            {showTimeField && (
              <TimeField
                label={"time"}
                value={state.timeValue}
                onChange={(t) => {
                  // Convert Time back to TimeValue if needed
                  state.setTimeValue(parseTime(t.toString()));
                }}
                placeholderValue={timePlaceholder}
                granularity={timeGranularity}
                minValue={timeMinValue}
                maxValue={timeMaxValue}
                hourCycle={props.hourCycle}
                hideTimeZone={props.hideTimeZone}
              />
            )}
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  ) : (
    <Popover modal open={state.isOpen} onOpenChange={state.setOpen}>
      <PopoverTrigger asChild>{trigger}</PopoverTrigger>
      <PopoverContent
        {...dialogProps}
        className={"w-full p-2"}
        align={!button ? "end" : "center"}
      >
        <div className={"relative space-y-3"}>
          <Calendar {...calendarProps} onChange={onValueChange} />
          {showTimeField && (
            <div className={""}>
              <TimeField
                label={"time"}
                value={state.timeValue}
                onChange={state.setTimeValue}
                placeholderValue={timePlaceholder}
                granularity={timeGranularity}
                minValue={timeMinValue}
                maxValue={timeMaxValue}
                hourCycle={props.hourCycle}
                hideTimeZone={props.hideTimeZone}
              />
            </div>
          )}
        </div>
      </PopoverContent>
    </Popover>
  );

  if (button === true) {
    return content;
  }

  return (
    <div
      ref={targetRef}
      className={cn(
        InputVariants({
          endPadding: !button,
        }),
        groupProps.className,
      )}
      {...groupProps}
    >
      <DatePickerField
        {...fieldProps}
        value={currentValue()}
        onChange={onValueChange}
      />
      {content}
    </div>
  );
}

type TimePickerProps = Omit<
  AriaTimeFieldProps<ZonedDateTime>,
  "minValue" | "maxValue" | "value" | "onChange"
> &
  InputProps & {
    className?: string;
    minValue?: string;
    maxValue?: string;
    value?: string | null;
    onChange?: (date: string | null) => void;
  };
export function TimePicker(defaultProps: TimePickerProps) {
  const { className, minValue, maxValue, value, onChange } = defaultProps;

  const [timeValue, setTimeValue] = useState<TimeValue | null>(null);

  useEffect(() => {
    if (value) {
      setTimeValue(parseTime(value));
    }
  }, [value]);

  const handleOnChange = (time: TimeValue) => {
    setTimeValue(time);
    const stringTime = time ? time.toString() : null;
    onChange?.(stringTime);
  };

  return (
    <TimeField
      className={className}
      value={timeValue}
      onChange={handleOnChange}
      minValue={minValue ? parseTime(minValue) : undefined}
      maxValue={maxValue ? parseTime(maxValue) : undefined}
    />
  );
}
