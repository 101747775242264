import {
  DirectoryDirI,
  DirectoryElementsI,
  DirectoryFileI,
} from "@/types/files";
import { PermissionsI } from "@/types/files";
import {
  ZustandHookSelectors,
  createSelectorHooks,
} from "auto-zustand-selectors-hook";
import { create } from "zustand";
import { persist } from "zustand/middleware";

type Action = {
  setModel: (payload: "my_drive" | "shared_with_me") => void;
  setSelected: (payload: {
    files: DirectoryFileI[];
    directories: DirectoryDirI[];
  }) => void;
  setFileToOpen: (payload: DirectoryFileI) => void;
  resetSelected: () => void;
  setPermissions: (payload: PermissionsI) => void;
  isSelected: (id: string, variant?: "file" | "directory") => boolean;
  selectedSize: (variant?: "file" | "directory") => number;
  setOpenViewer: (open: boolean) => void;
  setOpenEditName: (open: boolean) => void;
  setOpenShare: (open: boolean) => void;
  setOpenMove: (open: boolean) => void;
  setOpenDownload: (open: boolean) => void;
  setOpenMoreInfo: (open: boolean) => void;
  setOpenDelete: (open: boolean) => void;
  setOpenCreateFile: (open: boolean) => void;
  setOpenCreateFolder: (open: boolean) => void;
  setOpenCreateDir: (open: boolean) => void;
  OnFSEntityClick: (params: {
    file?: DirectoryFileI;
    directory?: DirectoryDirI;
    event?: React.PointerEvent;
  }) => void;
};
type State = {
  model: "my_drive" | "shared_with_me";
  selected: DirectoryElementsI;
  permissions: PermissionsI;
  fileToOpen?: DirectoryFileI;
  openViewer: boolean;
  openDelete: boolean;
  openEditName: boolean;
  openShare: boolean;
  openMove: boolean;
  openDownload: boolean;
  openMoreInfo: boolean;
  openCreateFile: boolean;
  openCreateFolder: boolean;
  openCreateDir: boolean;
};

type FSEntityType = {
  file?: DirectoryFileI;
  directory?: DirectoryDirI;
  event?: React.PointerEvent;
};

const useFilesStorageBase = create<Action & State>()(
  persist(
    (set, get) => ({
      model: "my_drive",
      selected: { files: [], directories: [] },
      permissions: { read: false, write: false, edit: false },
      openDelete: false,
      fileToOpen: undefined,
      openViewer: false,
      openEditName: false,
      openShare: false,
      openMove: false,
      openDownload: false,
      openMoreInfo: false,
      openCreateFile: false,
      openCreateFolder: false,
      openCreateDir: false,
      setFileToOpen: (payload) => set({ fileToOpen: payload }),
      setSelected: (payload) => set({ selected: payload }),
      setPermissions: (payload) => set({ permissions: payload }),
      resetSelected: () => set({ selected: { files: [], directories: [] } }),
      isSelected: (id: string, variant) => {
        const { selected } = get();
        switch (variant) {
          case "file":
            return selected.files.some((element) => element.id === id);
          case "directory":
            return selected.directories.some((element) => element.id === id);
          default:
            return (
              selected.files.some((element) => element.id === id) ||
              selected.directories.some((element) => element.id === id)
            );
        }
      },
      selectedSize: (variant) => {
        const { selected } = get();
        switch (variant) {
          case "file":
            return selected.files.length || 0;
          case "directory":
            return selected.directories.length || 0;
          default:
            return selected.files.length + selected.directories.length || 0;
        }
      },
      setModel: (payload) => set({ model: payload }),
      setOpenViewer: (open: boolean) => set({ openViewer: open }),
      setOpenEditName: (open: boolean) => set({ openEditName: open }),
      setOpenShare: (open: boolean) => set({ openShare: open }),
      setOpenMove: (open: boolean) => set({ openMove: open }),
      setOpenDownload: (open: boolean) => set({ openDownload: open }),
      setOpenMoreInfo: (open: boolean) => set({ openMoreInfo: open }),
      setOpenDelete: (open: boolean) => set({ openDelete: open }),
      setOpenCreateFile: (open: boolean) => set({ openCreateFile: open }),
      setOpenCreateFolder: (open: boolean) => set({ openCreateFolder: open }),
      setOpenCreateDir: (open: boolean) => set({ openCreateDir: open }),
      OnFSEntityClick: ({ file, directory, event }: FSEntityType) => {
        const { selected, isSelected, setSelected } = get();

        const element = file || directory;
        if (!element) return;

        const entityType = file ? "files" : "directories";
        const isTouch = event?.nativeEvent.pointerType === "touch";

        const handleEntitySelection = () => {
          const isElementSelected = file
            ? isSelected(element.id, "file")
            : isSelected(element.id, "directory");

          const selectedCopy: DirectoryElementsI = {
            files: [...selected.files],
            directories: [...selected.directories],
          };

          if (isElementSelected) {
            if (entityType === "files") {
              selectedCopy.files = selectedCopy.files.filter(
                (el) => el.id !== element.id,
              );
            } else {
              selectedCopy.directories = selectedCopy.directories.filter(
                (el) => el.id !== element.id,
              );
            }
          } else {
            if (entityType === "files") {
              selectedCopy.files.push(element as DirectoryFileI);
            } else {
              selectedCopy.directories.push(element as DirectoryDirI);
            }
          }

          return selectedCopy;
        };

        const handleSingleSelection = (): DirectoryElementsI => ({
          files: entityType === "files" ? [element as DirectoryFileI] : [],
          directories:
            entityType === "directories" ? [element as DirectoryDirI] : [],
        });

        // Handle different selection modes
        if (isTouch || event?.ctrlKey) {
          setSelected(handleEntitySelection());
        } else if (event?.shiftKey) {
          // TODO: Implement range selection
        } else {
          setSelected(handleSingleSelection());
        }
      },
    }),
    {
      name: "files-storage",
      partialize: (state) => ({
        model: state.model,
      }),
    },
  ),
);

const useFilesStorage = createSelectorHooks(
  useFilesStorageBase,
) as typeof useFilesStorageBase & ZustandHookSelectors<Action & State>;

export { useFilesStorage };
