import { httpErrorHandler } from "@/api/api";
import {
  useDeletePostFileMutation,
  useEditPostMutation,
} from "@/api/queries/postsQueries";
import { Button } from "@/components/ui/button/Button";
import {
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog/Dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form/Form";
import { Input } from "@/components/ui/input/Input";
import FileInput from "@/components/ui/input/file-input/FileInput";
import FileInputCard from "@/components/ui/input/file-input/FileInputCard";
import { Textarea } from "@/components/ui/input/textarea/Textarea";
import { Label } from "@/components/ui/label/Label";
import { Spinner } from "@/components/ui/spinner/Spinner";
import { useToast } from "@/components/ui/toast/useToast";
import { PostEditSchema, PostEditSchemaType } from "@/schemas/post.schema";
import { FilesI } from "@/types/files";
import { PostsI } from "@/types/posts";
import { serializeData } from "@/utils/serializeData";
import { yupResolver } from "@hookform/resolvers/yup";
import { ChangeEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

type PostEditFormPropsT = {
  onClose: () => void;
  open: boolean;
  post: PostsI | undefined;
};

export default function EditPost({ onClose, open, post }: PostEditFormPropsT) {
  const { toast } = useToast();
  const [files, setFiles] = useState<File[]>([]);

  const [currentFiles, setCurrentFiles] = useState<FilesI[]>([]);
  const { mutateAsync: editPost, isPending } = useEditPostMutation();
  const { mutateAsync: deletePostFile } = useDeletePostFileMutation(post?.id);

  useEffect(() => {
    if (open && post && post.files) {
      setCurrentFiles(post.files);
    }
  }, [open, post]);

  const handleAddFiles = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const newFiles = Array.from(e.target.files).map((file) => {
      if (file.size > 500 * 1024 * 1024) {
        toast({
          variant: "destructive",
          title: "Błąd",
          description: "Maksymalny rozmiar pliku to 500MB",
        });
      }
      return file;
    });
    setFiles([...files, ...newFiles]);
    return e.target.files;
  };

  const handleClose = () => {
    onClose();
    setFiles([]);
    form.reset();
  };

  const onSubmit = async (data: PostEditSchemaType) => {
    if (post?.files) {
      const filesToDelete = post.files.filter(
        (file) => !currentFiles.includes(file),
      );

      for (const file of filesToDelete) {
        await deletePostFile(file.id);
      }
    }

    editPost({ ...data, attachments: files })
      .then(() => {
        handleClose();
      })
      .catch((error) => {
        const { title, detail } = httpErrorHandler(error);
        toast({
          variant: "destructive",
          title: title,
          description: detail,
        });
      });
  };

  function onRemove(fileData: FilesI | File) {
    if (fileData instanceof File) {
      if (!files) return;
      const newFiles = files.filter((file) => file !== fileData);
      setFiles(newFiles);
    } else {
      setCurrentFiles((curr) => curr.filter((file) => file.id !== fileData.id));
    }
  }

  const form = useForm<PostEditSchemaType>({
    mode: "onBlur",
    defaultValues: {
      name: "",
      content: "",
      active: true,
      attachments: undefined,
    },
    values: post && PostEditSchema.cast(serializeData(post)),
    resolver: yupResolver(PostEditSchema),
  });

  return (
    <DialogContent
      className={"max-w-[65ch]"}
      onCloseAutoFocus={handleClose}
      onEscapeKeyDown={handleClose}
    >
      {isPending && (
        <div
          className={
            "absolute inset-0 z-20 m-auto flex flex-wrap items-center justify-center gap-3 bg-bg-container"
          }
        >
          <Spinner size={"lg"} />
          <h5 className={"font-medium"}>Trwa edycja posta...</h5>
        </div>
      )}

      <DialogHeader>
        <DialogTitle>Edytuj Post</DialogTitle>
      </DialogHeader>
      <Form {...form}>
        <DialogBody className={"flex flex-col gap-4"}>
          <form
            className={"flex flex-col gap-3"}
            onSubmit={form.handleSubmit(onSubmit)}
            noValidate
          >
            <FormField
              name={"name"}
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Tytuł</FormLabel>
                  <FormControl>
                    <Input {...field} placeholder={"Tytuł"} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name={"content"}
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Treść</FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder={"Co chcesz przekazać ?"}
                      className={"min-h-[10rem]"}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className={"flex flex-col gap-2"}>
              <Label>Media</Label>
              <FileInput onAddFiles={handleAddFiles}>
                {files &&
                  files.map((file, index) => (
                    <FileInputCard
                      key={index}
                      file={file}
                      onRemove={() => onRemove(file)}
                    />
                  ))}
                {currentFiles.map((file) => (
                  <FileInputCard
                    key={file.id}
                    file={file}
                    onRemove={() => onRemove(file)}
                  />
                ))}
              </FileInput>
            </div>
          </form>
        </DialogBody>
        <DialogFooter>
          <Button
            onClick={handleClose}
            type={"button"}
            variant={"flat"}
            variantColor={"muted"}
            className={"sm:w-full"}
          >
            Anuluj
          </Button>
          <Button
            onClick={form.handleSubmit(onSubmit)}
            type={"submit"}
            variant={"flat"}
            variantColor={"brand"}
            className={"sm:w-full"}
          >
            Zapisz
          </Button>
        </DialogFooter>
      </Form>
    </DialogContent>
  );
}
