import Folder from "@/assets/images/folder.svg?react";
import { Button } from "@/components/ui/button/Button";
import { FileUploadTrigger } from "@/components/ui/file-upload/FileUpload";
import { useFilesStorage } from "@/store/filesStore";
import { FilePlus2, FolderPlus } from "lucide-react";
import { useCallback } from "react";

type FilesPageEmptyProps = {
  isError: boolean;
  isLoading: boolean;
};

export default function FilesPageEmpty(props: FilesPageEmptyProps) {
  const { isError, isLoading } = props;
  const { setOpenCreateDir, permissions } = useFilesStorage();

  const handleOnCreateDir = useCallback(() => {
    setOpenCreateDir(true);
  }, [setOpenCreateDir]);

  return (
    <div
      className={
        "flex h-full w-full flex-col items-center justify-center gap-5"
      }
    >
      <Folder className={"max-h-[200px] max-w-full"} />
      <div className={"flex flex-col gap-2"}>
        <h2 className={"text-center text-2xl font-semibold text-fg-primary"}>
          Folder jest pusty
        </h2>
        <p className={"text-center text-xs text-fg-muted"}>
          folder jest pusty, dodaj plik lub folder...
        </p>
      </div>
      <div className={"flex flex-col gap-4"}>
        <FileUploadTrigger multiple={true}>
          <Button
            className={"max-w-[40ch]"}
            variant={"outline"}
            variantColor={"muted"}
            icon={<FilePlus2 />}
            iconPosition={"left"}
            disabled={isError || isLoading || !permissions.write}
          >
            Dodaj nowy plik
          </Button>
        </FileUploadTrigger>

        <Button
          onClick={handleOnCreateDir}
          className={"max-w-[40ch]"}
          variant={"outline"}
          variantColor={"muted"}
          icon={<FolderPlus />}
          iconPosition={"left"}
          disabled={isError || isLoading || !permissions.write}
        >
          Dodaj nowy folder
        </Button>
      </div>
    </div>
  );
}
